import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import {
  Hardware,
  Harness,
  HarnessApiService,
  MomentService,
  Vehicle,
  VehicleGroup,
  VehicleType,
} from '@fc-core';
import { VehiclePart } from '../vehicle-part-selector/vehicle-part-selector.component';
import { CameraInfo } from '../../../models/camera/camera-info';
import { FormValidation } from '@fc-core/form-utils/form-validation';
import { DeviceInfo } from '../device.info';
import { DialogsService } from '@fc-core/services/dialogs.service';

@Component({
  selector: 'fc-core-vehicle-form',
  templateUrl: './core-vehicle-form.component.html',
  styleUrls: ['./core-vehicle-form.component.scss'],
})
export class CoreVehicleFormComponent {
  @Input() nonTracker: boolean;
  @Input() vehicle: Vehicle;
  @Input() loading: boolean;
  @Input() vehicleForm: FormGroup;
  @Input() hardware: Hardware[];
  @Input() harness: Harness[];
  @Input() groups: VehicleGroup[];
  @Input() cameraList: CameraInfo[];
  @Input() vehicleTypes: VehicleType[];
  @Input() noHardware: boolean;
  @Output() partSelectedEmitter = new EventEmitter<VehiclePart>();
  @Output() formSubmitted = new EventEmitter();
  render: boolean;
  currentYear = this.moment.moment().get('year');

  constructor(
    private _sanitizer: DomSanitizer,
    private dialogService: DialogsService,
    private moment: MomentService,
  ) {
    // todo hotfix for form field label bug on ios (remove when fixed)
    setTimeout(() => (this.render = true));
  }

  @Input() set error(error: any) {
    FormValidation.handleError(this.vehicleForm)(error);
  }

  get hardwareError(): boolean {
    return this.vehicleForm.get('hardware')?.hasError('invalid');
  }

  get cameraError(): boolean {
    return this.vehicleForm.get('camera').hasError('invalid');
  }

  get isFormValid(): boolean {
    return this.vehicleForm.valid;
  }

  get photoSrc(): string {
    if (!this.vehicleForm.get('photo').value) return null;
    if (this.vehicleForm.get('photo').value?.original) {
      return this.vehicleForm.get('photo').value.scaledImg;
    }
    return this._sanitizer.bypassSecurityTrustUrl(
      URL.createObjectURL(this.vehicleForm.get('photo').value),
    ) as string;
  }

  get selectedCamera(): DeviceInfo {
    const camera = this.cameraList?.find(
      (camera) => camera.id === this.vehicleForm.get('camera').value,
    )?.serial;
    if (!camera) return;
    return {
      title: 'DVR',
      subtitle: `Camera: ${camera}`,
      partImage: 'assets/images/dvr.png',
    };
  }

  get noDeviceTitle(): DeviceInfo {
    return {
      title: 'No device',
      partImage: `assets/images/no-device.png`,
    };
  }

  get selectedPart(): DeviceInfo {
    if (this.noHardware) return this.noDeviceTitle;
    let payload: DeviceInfo;
    const hardware = this.hardware?.find(
      (item) => this.vehicleForm.get('hardware')?.value === item.id,
    );
    const harness = this.harness?.find(
      (item) => this.vehicleForm.get('harness')?.value === item.id,
    );

    if (hardware) {
      payload = {
        title: 'ODB',
        subtitle: `Tracker: ${hardware?.serial}`,
        partImage: `assets/images/${
          hardware && harness ? 'harness' : 'odb'
        }.png`,
      };
    }
    if (hardware && harness) {
      payload.title = 'Harness';
      payload.harnessImage =
        HarnessApiService.harnessIconDictionary[harness.type];
      payload.subtitle += ` | Harness: ${harness?.serial}`;
    }
    return payload;
  }

  get isDisabled(): boolean {
    return (
      this.vehicleForm.invalid || this.loading || this.vehicleForm.pristine
    );
  }

  partSelected(part: VehiclePart) {
    this.partSelectedEmitter.emit(part);
  }

  selectDvrPart() {
    if (this.noHardware) return;
    this.partSelectedEmitter.emit({
      name: 'DVR',
      image: 'assets/images/dvr.png',
      value: 'dvr',
    });
  }

  onImageDropped(images: File[]): void {
    const [file] = images;
    this.vehicleForm.get('photo').setValue(file);
    this.vehicleForm.markAsDirty();
  }

  submitForm(): void {
    if (this.vehicleForm.invalid || this.loading) return;
    this.vehicleForm.markAllAsTouched();
    if (this.isFormValid) this.formSubmitted.emit(this.vehicleForm.value);
  }

  closeVehicleForm(): void {
    this.dialogService.openConfirmDialog({
      title: 'Close vehicle form?',
      message: 'Are you sure want to leave vehicle creation form?',
      buttonText: 'Close',
      buttonColor: 'accent',
      action: () => this.dialogService.closeAll(),
    });
  }

  deletePhoto() {
    this.vehicleForm.get('photo').setValue(null);
    this.vehicleForm.markAsDirty();
  }
}
