import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreVehicleFormComponent } from './core-vehicle-form/core-vehicle-form.component';
import { VehiclePartSelectorComponent } from './vehicle-part-selector/vehicle-part-selector.component';
import { VehiclePartDropdownComponent } from './vehicle-part-selector/vehicle-part-dropdown.component';
import { MatIconModule } from '@angular/material/icon';
import { OverlayModule } from '@angular/cdk/overlay';
import { VehiclePartSelectWithDropdownComponent } from './vehicle-part-selector/vehicle-part-select-with-dropdown.component';
import { AddDeviceInputComponent } from './add-device/add-device-input.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AddHarnessComponent } from './add-device/add-harness.component';
import { AddOdbComponent } from './add-device/add-odb.component';
import { AddDvrComponent } from './add-device/add-dvr.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { DropAreaModule } from '@fc-shared/ui/drop-area/drop-area.module';
import { BarcodeScannerComponent } from './add-device/barcode-scanner.component';
import { SearchDropdownModule } from '@fc-shared/ui/search-dropdown/search-dropdown.module';
import { FormUtilsModule } from '@fc-core/form-utils/form-utils.module';
import { VehicleManufactureAutocompleteModule } from '../vehicle-form/vehicle-manufacture-autocomplete/vehicle-manufacture-autocomplete.module';
import { VehiclePhotoComponent } from './add-device/vehicle-photo.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatSliderModule } from '@angular/material/slider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { LoaderButtonModule } from '@fc-shared/ui/loader-button/loader-button.module';
import { AddDvrDialogComponent } from './add-device/add-dvr.dialog-component';
import { TextButtonComponent } from '@fc-shared/ui/buttons/text-button.component';
import { FilledButtonComponent } from '@fc-shared/ui/buttons/filled-button.component';
import { StrokedButtonComponent } from '@fc-shared/ui/buttons/stroked-button.component';
import { IconComponent } from '@fc-shared/ui/icon/icon.component';
import { IconButtonComponent } from '@fc-shared/ui/buttons/icon-button.component';
import { DropdownModule } from '@fc-shared/ui/dropdowns/dropdown.module';

@NgModule({
  declarations: [
    CoreVehicleFormComponent,
    VehiclePartSelectorComponent,
    VehiclePartDropdownComponent,
    VehiclePartSelectWithDropdownComponent,
    AddDeviceInputComponent,
    AddHarnessComponent,
    AddOdbComponent,
    AddDvrComponent,
    BarcodeScannerComponent,
    VehiclePhotoComponent,
    AddDvrDialogComponent,
  ],
  exports: [
    CoreVehicleFormComponent,
    AddHarnessComponent,
    AddOdbComponent,
    AddDvrComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    OverlayModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    DropAreaModule,
    SearchDropdownModule,
    FormUtilsModule,
    VehicleManufactureAutocompleteModule,
    MatMenuModule,
    MatSliderModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatRadioModule,
    LoaderButtonModule,
    TextButtonComponent,
    FilledButtonComponent,
    StrokedButtonComponent,
    IconComponent,
    IconButtonComponent,
    DropdownModule,
  ],
})
export class CoreVehicleFormModule {}
