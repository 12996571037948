import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  VehicleDetailPageComponent,
  VehicleDetailTabComponent,
  VehicleDriversTabComponent,
  VehicleEngineLightTabComponent,
  VehicleGroupsPageComponent,
  VehicleMaintenanceTabComponent,
  VehiclePageComponent,
  VehiclePhotosTabComponent,
} from './containers';
import {
  DeleteConfirmComponent,
  GroupDeleteConfirmComponent,
  RequestVideoDialogComponent,
  VehicleCreateDialogComponent,
  VehicleDvrComponent,
  VehicleGroupCreateDialogComponent,
  VehicleGroupDetailsComponent,
  VehicleGroupSidebarComponent,
  VehicleSidebarComponent,
} from './components';
import { CoreModalOpenerComponent } from '@fc-core/core-modal-opener/core-modal-opener.component';
import { hasCameraGuard } from '@fc-vehicles/has-camera.guard';

const routes: Routes = [
  { path: '', redirectTo: 'main', pathMatch: 'full' },
  {
    path: 'main',
    component: VehiclePageComponent,
    children: [
      { path: '', redirectTo: 'vehicles', pathMatch: 'full' },
      {
        path: 'vehicles',
        data: {
          type: 'vehicles',
          quickActionType: 'vehicles',
        },
        children: [
          {
            path: ':id',
            component: VehicleDetailPageComponent,
            children: [
              { path: '', redirectTo: 'details', pathMatch: 'full' },
              { path: 'details', component: VehicleDetailTabComponent },
              {
                path: 'dvr',
                component: VehicleDvrComponent,
                canActivate: [hasCameraGuard],
              },
              { path: 'drivers', component: VehicleDriversTabComponent },
              {
                path: 'engine-light',
                component: VehicleEngineLightTabComponent,
              },
              {
                path: 'maintenance',
                component: VehicleMaintenanceTabComponent,
              },
              { path: 'photos', component: VehiclePhotosTabComponent },
              {
                path: 'notes',
                loadChildren: () =>
                  import('./vehicle-notes/vehicle-notes.module').then(
                    (m) => m.VehicleNotesModule,
                  ),
              },
            ],
          },
          {
            path: 'add-vehicle',
            outlet: 'modal',
            component: CoreModalOpenerComponent,
            data: {
              dialog: VehicleCreateDialogComponent,
              modalType: 'create',
              confirm: true,
            },
          },
          {
            path: 'update-vehicle',
            outlet: 'modal',
            component: CoreModalOpenerComponent,
            data: {
              dialog: VehicleCreateDialogComponent,
              modalType: 'update',
              confirm: true,
            },
          },
          {
            path: 'delete-vehicle',
            outlet: 'modal',
            component: CoreModalOpenerComponent,
            data: {
              dialog: DeleteConfirmComponent,
              modalType: 'delete',
              additionalClass: 'full-screen-without-border',
            },
          },
          {
            path: 'request-video/:date',
            outlet: 'modal',
            component: CoreModalOpenerComponent,
            data: { dialog: RequestVideoDialogComponent, modalType: 'update' },
          },
          {
            path: '',
            component: VehicleSidebarComponent,
            outlet: 'sidebar',
          },
        ],
      },
      {
        path: 'vehicle-groups',
        data: {
          type: 'vehicle-groups',
          quickActionType: 'vehicle-groups',
        },
        children: [
          {
            component: VehicleGroupsPageComponent,
            path: ':id',
            children: [
              { path: '', component: VehicleGroupDetailsComponent },
              {
                path: 'edit-group',
                outlet: 'modal',
                component: CoreModalOpenerComponent,
                data: {
                  dialog: VehicleGroupCreateDialogComponent,
                  modalType: 'update',
                },
              },
              {
                path: 'delete-group',
                outlet: 'modal',
                component: CoreModalOpenerComponent,
                data: {
                  dialog: GroupDeleteConfirmComponent,
                  confirm: true,
                },
              },
            ],
          },
          {
            path: 'add-group',
            outlet: 'modal',
            component: CoreModalOpenerComponent,
            data: {
              dialog: VehicleGroupCreateDialogComponent,
              modalType: 'create',
            },
          },
          {
            path: '',
            component: VehicleGroupSidebarComponent,
            outlet: 'sidebar',
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class VehiclesRoutingModule {}
